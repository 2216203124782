import {DexieSetup} from './Dexie';
import AutocompleteSetup from './Autocomplete';

/**
 * Automatically adds target blank for external links
 */
function enableAutoTargetBlankLinks() {

    let internal = location.host.replace("www.", "");
    internal = new RegExp(internal, "i");
      
    let a = document.getElementsByTagName('a');
    
    for (var i = 0; i < a.length; i++) {
        let href = a[i].host;
        if( !internal.test(href) ) {
            a[i].setAttribute('target', '_blank');
        }
    }
};

/**
 * Determine if is a specific screen.
 * 
 * @param  {String} screen
 * 
 * @return {Boolean}
 */
export const getScreen = () => {
    return document.body.getAttribute('app--current-screen');
}

export const isScreen = (screen) => {
    return getScreen() === screen;
}

function currentUrl(key) {
    
    let url = new URL(window.location.href);

    if( key ) {
        let params = new URLSearchParams(url.search);
        return params.get(key);
    }

    return url;
}

export function boot() {
    enableAutoTargetBlankLinks()

    // Initialize Dexie IndexedDB Setup
    DexieSetup.init()

    // Initialize Autocomplete Search Functionality
    if( isScreen('/')) {
        AutocompleteSetup.init()
    }

    if( isScreen('campaigns')) {
        if( Boolean(currentUrl('flush-queue')) === true )  {

            DexieSetup.deleteDb('properties_queue_v2').then( () => {
                console.log('Dexie DB flushed');
            }).catch( (err) => {
                console.log('Dexie DB coult not be deleted: ' + err.message);
            })
        }
    }

    if( isScreen('campaigns/build') ) {

        let editableValues = {};
        let contentEditables = document.querySelectorAll('[contenteditable="true"]');
        let editableHiddenInput = document.getElementById('content_editable_placeholders');
        
        function getContentEditableValues(updated) {

            if( updated !== undefined ) {

                let updatedElementId = updated.getAttribute('contenteditable-id');
                // update object with new content
                editableValues[updatedElementId] = updated.textContent.trim();
                // update input with new contents object
                editableHiddenInput.value = JSON.stringify(editableValues);

            } else {
                for (var i = 0; i < contentEditables.length; i++) {
                    let identifier = contentEditables[i].getAttribute('contenteditable-id');
                    Object.assign(editableValues, {[identifier] : contentEditables[i].textContent.trim() })
                }
            }

            editableHiddenInput.value = JSON.stringify(editableValues);
        }

        Camino.contentEditable({
            textOnly: false,
            onKeyup: function(el, caret) {
                return getContentEditableValues(el.get()) // update data based on input
            },
        });

        getContentEditableValues() // retrieve default values

    }
}