import autoComplete from "@tarekraafat/autocomplete.js";
import {LocalDatabase, PropertiesQueue} from './Dexie';

function getAppUrl(path)
{
    return __settings.app_url + path;
}

const SearchEndpoint = getAppUrl(__settings.api_base + '/' + __settings.search_endpoint),
    __searchVersion = __settings.latest_search_update;

let toggleElement,
    newCampaignBtn      = document.querySelector('#app--create-campaign'),
    numberOfProperties  = 0,
    queueProperties     = [],
    cancelQueueBtn,
    propWrapper,
    propsInQueue = [];

let countQueueProps = 0, propertiesId = []

const AutocompleteSetup = {

    async init() {

        // When available, renders queue bar with
        // the properties added before.
        if( await this.hasQueueProperties() ) {
            await this.renderPropertiesQueueContainer();
            newCampaignBtn.addEventListener('click', this.campaignBtnListener);
        } else {
            newCampaignBtn.setAttribute('disabled', 'disabled');
            newCampaignBtn.removeEventListener('click', this.campaignBtnListener);
        }

        const AutoCompleteSearch = new autoComplete({
            data: {
              src: async () => {
                    // Creating or getting search data from Indexed Database via user's browser
                    const source = await this.updateBrowserLocalStorage();
                    // Format data into JSON
                    const data = await source;
                    // Return Fetched data
                    return data;
            },
            key: ["title", "destination"],
                results: (list) => {
                    return list;
                },
                cache: false
            },
            sort: (a, b) => {                    // Sort rendered results ascendingly | (Optional)
                if (a.match < b.match) return -1;
                if (a.match > b.match) return 1;
                return 0;
            },
            selector: "#searchbar--input",
            // observer: true,
            threshold: 3,
            debounce: 170,
            searchEngine: "strict",
            resultsList: {
                container: source => {
                    source.setAttribute("id", "list");
                },
                destination: "#searchbar--input",
                position: "afterend",
                element: "ul"
            },
            maxResults: 9999,
            highlight: false,
            resultItem: {
                content: (data, source) => {
                    var thumbSrc = data.value.media_featured;
                    if(!thumbSrc.startsWith('http') && ! thumbSrc.startsWith('//cdn.')) {
                        thumbSrc = "https://cdn.fantasiavillas.com" + thumbSrc
                    }

                    let title = '<span class="search--item--title">' + data.value.title + '</span>',
                        destination = '<code class="d-block"><small>' + data.value.destination + '</small></code>',
                        thumb = '<img class="float-start rounded me-3" src="' + thumbSrc + '" height="55px" width="55px" style="background-color:#EEE">';

                    source.innerHTML = thumb + title + destination;
                },
                element: "li"
            },
            noResults: (dataFeedback, generateList) => {
                // Generate autoComplete List
                generateList(AutoCompleteSearch, dataFeedback, dataFeedback.results);
                // No Results List Item
                const result = document.createElement("li");
                result.setAttribute("class", "no_result d-block");
                result.setAttribute("tabindex", "1");
                result.innerHTML = `<span class="font-weight-normal">Couldn't find anything related to <code>"${dataFeedback.query}"</code></span>`;

                document.querySelector('#searchbar--area ul').appendChild(result);
            },
            onSelection: async (feedback) => {

                document.querySelector("#searchbar--input").value = '';

                let propertyName = feedback.selection.value.title,
                    propertyId = feedback.selection.value.property_id,
                    propertyDestination = feedback.selection.value.destination,
                    propertyThumb = feedback.selection.value.media_featured;

                await this.addPropertyInQueue(propertyId, propertyName, propertyThumb, propertyDestination);

            }
        });
    },

    campaignBtnListener() {
        window.location.href = getAppUrl(newCampaignBtn.getAttribute('href'));
    },

    // Fetching the search results database and prepare
    // for storing as Local Storage to the user's browser.
    async fetchSearchResults()
    {
        await fetch(SearchEndpoint).then(function (response) {
            return response.json();
        }).then(function (getSearchData) {

            LocalDatabase.search_results.bulkAdd(getSearchData).then(function() {
                return LocalDatabase.search_results.orderBy('title').toArray();
            }).then(function (results) {
                console.info("%c Local Database successfuly initiated:", 'padding:5px; border-radius:25px; background: blue; color: white');
                console.log(results);
            }).catch(function (e) {
                console.log("Error: " + (e.stack || e));
            });

        }).catch(function (error) {
            console.warn('Error while fetching database search results with the browser...', error);
        });
    },

    /**
     * Update the local storage with the latest results from fetch.
     */
    async updateBrowserLocalStorage()
    {
        let results = await LocalDatabase.search_results.orderBy('title').toArray();

        if( results.length === 0 ) {        
            await this.fetchSearchResults();
            results = await LocalDatabase.search_results.orderBy('title').toArray();
        } else {
            console.info("%c Serve search results from Indexed Database", 'padding:5px; border-radius:25px; background: lightblue; color: black');
        }

        return results;
    },

    /**
     * Adding property in Queue
     */
    async addPropertyInQueue(getId, getName, getThumb, getLocation)
    {
        let _this = this;
        PropertiesQueue.queue.add({
            property_id: getId,
            name: getName,
            thumb: getThumb,
            destination: getLocation
        }).then(function() {
            return PropertiesQueue.queue.where({property_id: getId}).first();
        }).then(function (item) {
            _this.showPropertyInQueue(item)
        }).catch(function (error) {
            console.warn('Error while fetching properties...', error.stack);
        });
    },

    /**
     * Create a list with all properties in Queue
     */
    async showPropertyInQueue(get)
    {

        let selectedProperty = document.createElement("li");
            selectedProperty.innerHTML = get.name,

            // Wraps selected properties in a container
            propWrapper = document.querySelector('#selected--properties');

        propWrapper.appendChild(selectedProperty);
        propWrapper.style.opacity = '1';

        // Set the property to the selected property pill.
        selectedProperty.setAttribute('property--id', get.destination + ':' + get.property_id);
        // selectedProperty.setAttribute('p', 'rmQueueItem('+get.property_id+');');

        // Increment number of properties when adding first time
        if( await this.hasQueueProperties() ) {
            numberOfProperties = await this.getQueueCountProperties();
        }
        
        countQueueProps++;
        propertiesId.push(get.destination + ':' + get.property_id);
        propsInQueue.push(selectedProperty)

        this.enablePropertyDeletion(propsInQueue);
        newCampaignBtn.addEventListener('click', this.campaignBtnListener);
        this.updateNewCampaignBtn(propertiesId, countQueueProps)
    },


    /**
     * Determine if the queue has any properties
     * @return boolean
     */
    async hasQueueProperties()
    {
        return await PropertiesQueue.queue.count() !== 0;
    },

    /**
     * Get number of properties from queue.
     * @return int
     */
    async getQueueCountProperties()
    {
        return await PropertiesQueue.queue.count();
    },

    /**
     * Remove an item from Queue
     */
    rmQueueItem(getId)
    {

        let removeablePropertyId = getId.split(':')[1];

        PropertiesQueue.queue.where({property_id: removeablePropertyId}).delete().then( (count) => {
            console.log(count)
            let rmvdItem = document.querySelector('#selected--properties li[property--id="'+getId+'"]');
                rmvdItem.remove();
                countQueueProps--;

            propertiesId = propertiesId.filter(item => item !== getId)
            this.updateNewCampaignBtn(propertiesId, countQueueProps <= 0 ? '' : countQueueProps)
        });
    },

    enablePropertyDeletion(updated) {
        let props = updated ? updated : propsInQueue
        for (var i = 0; i < props.length; i++) {
            props[i].addEventListener('click', function(e){
                let prop = e.currentTarget;
                this.rmQueueItem(prop.getAttribute('property--id'));
            }.bind(this))
        }
    },

    /**
     * Create Cancel Queue Button
     */
    async createCancelQueueBtn(propWrapper)
    {
        let cancelQueueBtn = document.createElement('a');
        cancelQueueBtn.innerHTML = 'Cancel';
        cancelQueueBtn.setAttribute('id', 'cancel--queue');
        cancelQueueBtn.setAttribute('class', 'btn btn-link btn-sm');

        cancelQueueBtn.addEventListener('click', async (e) => {
            await PropertiesQueue.queue.delete();
        });

        return propWrapper.appendChild(cancelQueueBtn);
    },

    /**
     * Interactive Create Campaign Button
     */
    updateNewCampaignBtn(properties, count)
    {
        // Retrieve the original path
        newCampaignBtn.setAttribute('href', '/campaigns/new?properties=' + properties.toString());
        newCampaignBtn.setAttribute('properties-no', count);

        if( ! newCampaignBtn.classList.contains('--creating')) {
            newCampaignBtn.classList.add('--creating')
        }

        if(count == '') {
            newCampaignBtn.setAttribute('disabled', 'disabled');
        } else {
            newCampaignBtn.removeAttribute('disabled');
        }
    },

    /**
     * When available it loads properties from queue,
     * even when the user leaves browser and comes back, thanks to IndexedDB
     */
    async renderPropertiesQueueContainer()
    {
        propWrapper = document.querySelector('#selected--properties');
        propWrapper.style.opacity = '1';
        countQueueProps = await this.getQueueCountProperties();

        let queue = await PropertiesQueue.queue.orderBy(name).toArray();

        queue.forEach(function(item) {
            let liQueue = document.createElement("li");
            let propId = item.destination + ':' + item.property_id
            liQueue.setAttribute('property--id', propId);
            // liQueue.setAttribute('onclick', 'rmQueueItem('+item.property_id+');');
            liQueue.innerHTML = item.name;
            propWrapper.appendChild(liQueue);
            propertiesId.push(propId);
            propsInQueue.push(liQueue);
        });

        this.enablePropertyDeletion(propsInQueue);
        this.updateNewCampaignBtn(propertiesId, countQueueProps)

    }
}

export default AutocompleteSetup;