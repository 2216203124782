import Dexie from 'dexie';

const LocalDatabase = new Dexie('search_database_v2');
const PropertiesQueue = new Dexie('properties_queue_v2');

const DexieSetup = {

    async init() {

        // Creating the Local Indexed Database which stores
        // local to user's browser all the search indexes for fast results.
        LocalDatabase.version(3.1).stores({
            search_results: "++id,title,destination,destination_id,property_id,media_featured"
        });

        PropertiesQueue.version(1.1).stores({
            queue: "property_id,title,destination,destination_id,media_featured"
        })

    },

    deleteDb(db_name) {
        return Dexie.delete(db_name);
    }
}

export {LocalDatabase, PropertiesQueue, DexieSetup};